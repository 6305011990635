.portfolio__container {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 2.5rem;
    width: 65%;
    margin: auto;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1rem 0 1rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.all__projects {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.all__projects__btn {
    margin: 3rem 0;
    width: 20%;
    background: var(--color-primary);
    color: var(--color-bg);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: var(--transition);
    text-align: center;
}


/* =================== MEDIA QUERIES (MEDIUM DEVICES) ============ */

@media screen and (max-width: 1224px) {

    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
        width: 80%;
    }
}


/* =================== MEDIA QUERIES (SMALL DEVICES) ============ */

@media screen and (max-width: 600px) {

    
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 90%;
    }

}

