header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* =========== CTA ============= */

.cta {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}


/* =========== SOCIALS ============= */

.header__socials {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: center;
}


/* =========== ME Image ============= */

.me {
    width: 22rem;
    height: 30rem;
    display: flex;
    align-self: center;
    justify-self: center;
}

.me img:hover {
    transition: ease-in 0.2s;
    transform: scale(1.1);
}




/* =========== SCROLL DOWN ============= */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-size: 0.9rem;
}





/* =================== MEDIA QUERIES (MEDIUM DEVICES) ============ */

@media screen and (max-width: 1024px) {

    header {
        height: 68vh;
    }
}


/* =================== MEDIA QUERIES (MEDIUM, SHORT DEVICES) ============ */

@media screen and (max-width: 1024px) and (max-height: 841px) {
    header {
        height: 100vh;
    }
}



/* =================== MEDIA QUERIES (SMALL DEVICES) ============ */

@media screen and (max-width: 600px) {

    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }

    .header__container {
        display: flex;
        flex-direction: column;
    }
}



/* =================== MEDIA QUERIES (EXTRA-SMALL DEVICES) ============ */

@media screen and (max-width: 320px) {

    .me{
        width: 18rem;
        height: 30rem;
        left: calc(50% - 9rem);

    }
}
