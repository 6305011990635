#projects {
    display: flex;
    flex-direction: column;
}

.project__header {
    display:  flex;
    align-self: center;
    gap: 1rem;
}

.project__categories{
    width: 75%;
    height: 5rem;
    background: var(--color-bg-variant);
    border-radius: 2rem;
    justify-self: center;
    align-self: center;
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem;
}

.project__categories li {
    font-weight: 600;
    cursor: pointer;
    background: rgba(0,0,0,0.2);
    padding: 0.6rem;
    border-radius: 2rem;
}

.project__categories li:hover {
    background: rgba(0,0,0,0.8);
}

.project__categories li.active {
    background: rgba(0,0,0,0.8);
}


.projects__container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2.5rem;
    margin: auto;
    padding: 3rem;
}

.projects__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
}

.projects__item  h3 {
    align-self: center;
    margin-bottom: 0.5rem;
}

.project__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 35px;
    background: var(--color-primary);
    color: white;
    border-radius: 0.5rem;
    margin-right: 1rem;
}

small {
    margin-bottom: 1.5rem;
}

.project__category {
    text-align: center;
    width: 100px;
    height: 25px;
    background: rgb(20, 51, 190);
    color: white;
    border-radius: 0.5rem;
    display: inline-block;
    margin-right: 1rem;
}

.projects__item-cta {
    display: flex;
}

.mobile-home {
    display: none;
}

.project__tech__container {
    display: flex;
    gap: 0.3rem;
}

.project__tech {
    background: rgb(20, 51, 190);
    height: 1.5rem;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 14px;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
}

@media screen and (max-width: 1500px) {

    .projects__container {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 2.5rem;
        margin: auto;
        padding: 3rem;
    }

}

@media screen and (max-width: 1200px) {

    .projects__container {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2.5rem;
        margin: auto;
        padding: 3rem;
    }

}

@media screen and (max-width: 825px) {
    

    .projects__item {
        width: 90%;
        margin: auto;
    }

    .projects__container {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 2.5rem;
        margin: auto;
        padding: 0;
    }

    .project__categories {
        width: 90%;
        margin-bottom: 2rem;
    }

    .project__categories li{
        font-size: 11px;
    }

    .mobile-home {
        display: block;
    }

    .mobile-home-bg {
        background: rgba(0,0,0,0.2);
        border-radius: 2rem;
        padding: 0.6rem;
    }
    
    .desktop-home {
        display: none;
    }

}